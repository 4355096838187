import React, { useContext, useEffect, useMemo } from 'react';
import { ModuleEditorLayout } from '@Pages/pages/layout/ModuleEditorLayout';
import { useNavigate, useParams } from 'react-router-dom';
import { BackofficeContext } from '@Context/BackofficeContext';
import { useFormik } from 'formik';
import { Module } from '@Types/Module';
import { ModuleInformation } from '@Pages/pages/Modules/EditModule/ModuleInformation';
import { ActivityInformation } from '@Pages/pages/Modules/EditModule/ActivityInformation';
import { Activity } from '@Types/Activity';
import { useApi } from '@Hooks/api';
import { usePromise } from '@Hooks/promise';
import { produce } from 'immer';
import _ from 'lodash';
import { ModuleSchema } from '@Schemas/module.schema';
import { ActivitySchema } from '@Schemas/activity.schema';
import { useToast } from '@Hooks/toast';

export type EditModuleProps = {};
export const EditModule: React.FC<EditModuleProps> = (props) => {
    const { success } = useToast();
    const params = useParams<{ module_id: string; activity_id: string }>();
    const { modules } = useContext(BackofficeContext);
    const navigate = useNavigate();
    const api = useApi();

    const module = useMemo(() => modules.find((m) => m.module_id === params.module_id), [params.module_id, modules]);

    const [onUpdateModule, loading] = usePromise(async (module: Module) => {
        const res = await api.module_call_update({
            module,
        });

        if (res.result === 'ok') {
            success('Modifications sauvegardées');
        }
    });

    const moduleFormik = useFormik({
        initialValues:
            module ||
            ({
                module_id: '',
                cover_image_url: null,
                activities: [],
                title: '',
                created_at: '',
                description: null,
                estimated_duration: 0,
                minimal_duration: 0,
                estimated_duration_unit: 's',
                quizz_type: 'default',
                type: 'quizz',
                updated_at: '',
            } satisfies Module),
        enableReinitialize: true,
        validationSchema: ModuleSchema,
        onSubmit: async (values) => {
            return onUpdateModule(values);
        },
    });

    const activity = useMemo(() => {
        return moduleFormik.values.activities.find((a) => a.activity_id === params.activity_id);
    }, [moduleFormik.values.activities, params.activity_id]);

    useEffect(() => {
        console.log('Errors', moduleFormik.errors);
    }, [moduleFormik.errors]);

    const [onActivityUpdate] = usePromise(async (module: Module, activity: Activity) => {
        console.log(module, activity);
        const updatedModule = produce(module, (draft) => {
            draft.activities = draft.activities.map((a) => (activity.activity_id === a.activity_id ? activity : a));
            return draft;
        });

        console.log(updatedModule);

        await onUpdateModule(updatedModule);
    });

    const onUpdateOrder = (activity_id: string, order: number) => {
        const oldActivities = [...moduleFormik.values.activities];

        const oldIndex = oldActivities.findIndex((a) => a.activity_id === activity_id);

        const activityToMove = { ...oldActivities[oldIndex] };

        if (oldIndex < order - 1) {
            oldActivities.splice(order - 1, 0, activityToMove);
            oldActivities.splice(oldIndex, 1);
        } else if (oldIndex > order - 1) {
            oldActivities.splice(oldIndex, 1);
            oldActivities.splice(order - 1, 0, activityToMove);
        }

        moduleFormik.setFieldValue('activities', oldActivities);
    };

    const activityFormik = useFormik<Activity>({
        enableReinitialize: true,
        initialValues:
            activity ||
            ({
                activity_id: '',
                type: 'course',
                title: '',
            } satisfies Activity),
        validationSchema: ActivitySchema,
        onSubmit: (value) => onActivityUpdate(moduleFormik.values, value),
    });

    useEffect(() => {
        console.log(activityFormik.errors);
    }, [activityFormik.errors]);

    async function onAddActivity() {
        const activities = [...moduleFormik.values.activities];
        if (module?.type === 'audit') {
            activities.push({
                type: 'ucq',
                is_response_img: false,
                answers: [
                    {
                        text_or_image: 'Oui',
                        is_response: true,
                    },
                    {
                        text_or_image: 'Non',
                        is_response: false,
                    },
                    {
                        text_or_image: 'N/A',
                        is_response: false,
                    },
                ],
                cover_video_url: [null, null, null, null, null],
                cover_image_url: [null, null, null, null, null],
                title: `Critère d'évaluation n°${activities.length + 1}`,
                description: '',
            });
        } else {
            activities.push({
                type: 'course',
                title: 'nouvelle activité',
                content: 'Description du cours',
                resource_pdf_url: null,
            });
        }
        await moduleFormik.setFieldValue('activities', activities, false);
        await moduleFormik.submitForm();

        const lastActivity = _.last(moduleFormik.values.activities);

        if (lastActivity?.activity_id)
            navigate(`/modules/${moduleFormik.values.module_id}/${lastActivity.activity_id}`);
        else navigate(`/modules/${moduleFormik.values.module_id}`);
    }

    async function onDuplicateActivity() {
        const activities = [...moduleFormik.values.activities];

        if (activity) {
            const index = activities.findIndex((a) => a.activity_id === activity.activity_id);

            const newActivity = {
                ...activity,
                title: `${activity.title} (copie)`,
            };

            delete newActivity.activity_id;

            activities.splice(index + 1, 0, newActivity);

            await moduleFormik.setFieldValue('activities', activities, false);
            await moduleFormik.submitForm();
        }
    }

    const onActivityDelete = (activity_id: string) => {
        const oldActivityIndex = moduleFormik.values.activities.findIndex((a) => a.activity_id === activity_id);
        moduleFormik.setFieldValue(
            'activities',
            moduleFormik.values.activities.filter((a) => a.activity_id !== activity_id)
        );
        moduleFormik.submitForm().then(() => {
            const nextActivity =
                moduleFormik.values.activities[
                    Math.min(oldActivityIndex + 1, moduleFormik.values.activities.length - 1)
                ];

            if (nextActivity) navigate(`/modules/${moduleFormik.values.module_id}/${nextActivity.activity_id}`);
            else navigate(`/modules/${moduleFormik.values.module_id}`);
        });
    };

    return (
        <ModuleEditorLayout
            module={module}
            onSave={params.activity_id ? activityFormik.submitForm : moduleFormik.submitForm}
            onAddActivity={onAddActivity}
            onDuplicateActivity={onDuplicateActivity}
            isActivity={Boolean(module && params.module_id && params.activity_id && activity)}
            loading={loading}
        >
            {module && params.module_id && !params.activity_id && (
                <ModuleInformation module={module} moduleFormik={moduleFormik} />
            )}
            {module && params.module_id && params.activity_id && activity && (
                <ActivityInformation
                    module={module}
                    moduleFormik={moduleFormik}
                    activity={activity}
                    key={activity.activity_id}
                    activityFormik={activityFormik}
                    setOrder={onUpdateOrder}
                    onDeleteActivity={onActivityDelete}
                />
            )}
        </ModuleEditorLayout>
    );
};
