import React, { useEffect, useState } from 'react';
import { Activity, QuizzActivity as QuizzActivityModel, QuizzAnswer } from '@Types/Activity';
import styled from 'styled-components';
import { getUrlForResource } from '@Utils/resource.utils';
import { Resource } from '@Types/Resource';
import { Galleria } from 'primereact/galleria';
import { Image } from 'primereact/image';
import { classNames } from 'primereact/utils';
import { ReadOnlyEditor } from '@Components/ReadOnlyEditor';
import { MCQ } from '@Pages/pages/Formations/PreviewFormation/ActivityPreview/QuizzActivity/MCQ';
import { UCQ } from '@Pages/pages/Formations/PreviewFormation/ActivityPreview/QuizzActivity/UCQ';

const ExplanationContainer = styled.div`
    background: #f1f5f9;
    border-left: 4px solid #2ecc71;
`;

export type QuizzActivityProps = {
    activity: Activity & QuizzActivityModel;
};
export const QuizzActivity: React.FC<QuizzActivityProps> = (props) => {
    const [selection, setSelection] = useState<QuizzAnswer>([]);

    const itemTemplate = (item: Resource) => {
        return (
            <Image
                preview
                src={item?.resource_url}
                alt={item?.resource_name}
                width="auto"
                height={'200px'}
                className="pb-5"
            />
        );
    };

    const thumbnailTemplate = (item: Resource) => {
        return <img src={item.resource_url} alt={item.resource_name} style={{ width: 'auto', height: '70px' }} />;
    };

    const [images, setImages] = useState<(null | Resource | undefined)[]>([]);
    const [videos, setVideos] = useState<Array<null | Resource | undefined>>([]);

    useEffect(() => {
        Promise.all(
            props.activity.cover_image_url.map(async (i?: string | null) => {
                if (i) {
                    return getUrlForResource(i);
                }
                return null;
            })
        ).then((imgs) => {
            setImages(imgs.filter((e) => !!e));
        });
        Promise.all(
            props.activity.cover_video_url.map(async (i?: string | null) => {
                if (i) {
                    return getUrlForResource(i);
                }
                return null;
            })
        ).then((vids) => {
            setVideos(vids.filter(Boolean));
        });
    }, [props.activity]);

    const responsiveGalleriaOptions = [
        {
            breakpoint: '768px',
            numVisible: 2,
        },
        {
            breakpoint: '450px',
            numVisible: 1,
        },
    ];

    return (
        <div className="w-full h-full">
            <div className={classNames('he-header--h1')}>{props.activity.title}</div>
            <div className={classNames('quill-custom', 'mt-4')}>
                <ReadOnlyEditor content={props.activity.question || ''} />
            </div>
            {images.length > 0 && (
                <div className="bg-gray-600 pt-5 mt-3">
                    <Galleria
                        circular
                        responsiveOptions={responsiveGalleriaOptions}
                        value={images}
                        numVisible={5}
                        item={itemTemplate}
                        style={{ height: '100%' }}
                        thumbnail={thumbnailTemplate}
                    />
                </div>
            )}
            {videos.length > 0 && (
                <div>
                    {videos.map((v) => {
                        if (v) {
                            return (
                                <>
                                    {v?.resource_url}
                                    {v?.resource_name}
                                </>
                            );
                        }
                        return null;
                    })}
                </div>
            )}
            <div className="mt-2">
                {props.activity.type === 'mcq' && (
                    <MCQ
                        is_done
                        answers={props.activity.answers}
                        selection={selection}
                        onSelect={setSelection}
                        is_image={props.activity.is_response_img}
                    />
                )}
                {props.activity.type === 'ucq' && (
                    <UCQ
                        is_done
                        answers={props.activity.answers}
                        selection={selection}
                        onSelect={setSelection}
                        is_image={props.activity.is_response_img}
                    />
                )}
            </div>
            {props.activity.answer_explanation && (
                <ExplanationContainer className="mt-2 py-2 px-3">
                    <div className="he-paragraph--medium gray-900">Correction</div>
                    <div className="he-paragraph--regular gray-400 mt-2">{props.activity.answer_explanation}</div>
                </ExplanationContainer>
            )}
        </div>
    );
};
