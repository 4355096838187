import { Module } from "@Types/Module";
import { Session, SessionExtended } from "@Types/Session";
import { AuditRecord, UserProgress, UserProgressItem } from "@Types/User";
import _ from "lodash";

export const isSessionStarted = (session: Session, progress?: UserProgress) => {
	const sessionProgress = progress?.[session.session_id];
	return !sessionProgress;
}

export const getLastActivity = (progress: UserProgress, session: SessionExtended<false>) => {

	const sessionProgress = progress[session.session_id];

	if (!sessionProgress) return undefined;

	const lastModuleId = _.last(_.intersection(session.formation.modules.map(e => e.module_id), Object.keys(sessionProgress)));

	if (!lastModuleId) return undefined;

	const lastModule = sessionProgress[lastModuleId];

	if (!lastModule) return undefined;

	const lastActivity = _.last(Object.entries(lastModule));

	if (!lastActivity) return undefined;

	//      module_id      activity_id      activity_history
	return {module_id: lastModuleId, activity_id: lastActivity[0], activity: lastActivity[1]}

}

export const getActivityProgression = (
    modules: Module[],
    progress: UserProgressItem[],
    records: AuditRecord[],
) => {
    const auditModules = modules.filter((m) => m.type === 'audit');
    const normalModules = modules.filter((m) => m.type !== 'audit');

    const normalModulesIds = normalModules.map((m) => m.module_id);

    const totalActivities = normalModules.reduce((a, c) => a + c.activities.length, 0);
    const totalActivitiesDone = progress
        .filter((p) => normalModulesIds.includes(p.module_id))
        .filter((p) => p.activity.done);

    const normalProgress = normalModules.length > 0 ? Math.min((100 * totalActivitiesDone.length) / totalActivities, 100) : 100;

    const [auditActivitiesDone, auditTotalActivities] = auditModules
        .map((c) => {
            const moduleRecords = records.filter((r) => r.module_id === c.module_id);
            const totalMaxRecords = Math.min(Math.max(c.min_records, moduleRecords.length), c.max_records);
            const total = c.activities.length * totalMaxRecords;
            const completedActivities = records.reduce((acc, record) => {
                return acc + record.answers.length;
            }, 0);

            return [completedActivities, total];
        })
        .reduce((acc, [completed, total]) => [acc[0] + completed, acc[1] + total], [0, 0]);

	const auditProgress = auditTotalActivities > 0 ? Math.min((100 * auditActivitiesDone) / auditTotalActivities, 100) : 100;

	console.log('normalProgress', normalProgress);
	console.log('auditProgress', auditProgress);

    return (normalProgress + auditProgress) / 2;
};