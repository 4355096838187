import { AuditModule, AuditModuleCategory } from '@Types/Module';
import { iconForActivity } from '@Utils/activity.utils';
import { getTextColor } from '@Utils/misc.utils';
import { uniq } from 'lodash';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import React, { useCallback } from 'react';
import styled from 'styled-components';

type Props = {
    module: AuditModule;
    categories: AuditModuleCategory[];
    setCategories: (categories: AuditModuleCategory[]) => void;
};
export const AssociationCategorie: React.FC<Props> = (props) => {
    const onAssociationChange = async (category_id: string, activity_id: string) => {
        
        const category = props.categories.find((t) => t.category_id === category_id);

        const moduleActivities = props.module.activities.map(a => a.activity_id);

        if (!category) return;

        props.setCategories(
            props.categories.map((t) => {
                if (t.category_id !== category_id)
                    return {
                        ...t,
                        activity_ids: t.activity_ids.filter((id) => id !== activity_id && moduleActivities.includes(id)),
                    };
                return {
                    ...t,
                    activity_ids: uniq([...t.activity_ids.filter(id => moduleActivities.includes(id)), activity_id]),
                };
            })
        );
    };

    const optionTemplate = useCallback(
        (option: { value: string; label: string }) => {
            const category = props.categories.find((c) => c.category_id === option.value);
            if (!category) return ' - ';
            return <CategoryTag background={category.color}>{option.label}</CategoryTag>;
        },
        [props.categories]
    );

    return (
        <div className="flex flex-column gap-3 w-6 mt-4">
            <div className="w-full">
                {props.module.activities.length === 0 && (
                    <div className="he-paragraph--small gray-500">Aucune activité configurée pour ce module</div>
                )}
                <div className="pl-4 flex flex-column gap-1 border-left-1 border-gray-500 w-full">
                    {props.module.activities.map((a) => {
                        if (!a.activity_id) return null;
                        const activityTheme = props.categories.find((c) => c.activity_ids.includes(a.activity_id!));
                        return (
                            <div
                                key={a.activity_id}
                                className="flex gap-2 align-items-center w-full justify-content-between"
                            >
                                <div className="he-paragraph--regular gray-600">
                                    <i className={classNames(iconForActivity(a.type), 'mr-1')} /> {a.title}
                                </div>
                                <div>
                                    <CustomDropdown
                                        options={props.categories.map((c) => ({
                                            label: c.label,
                                            value: c.category_id,
                                        }))}
                                        value={activityTheme?.category_id}
                                        placeholder="Sélectionner un thème"
                                        itemTemplate={optionTemplate}
                                        valueTemplate={(option) =>
                                            option ? (
                                                optionTemplate(option)
                                            ) : (
                                                <span className="he-paragraph--small gray-600">
                                                    Aucun thème associé
                                                </span>
                                            )
                                        }
                                        onChange={(e) => {
                                            onAssociationChange(e.value, a.activity_id!);
                                        }}
                                    />
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

const CategoryTag = styled.div<{
    background?: string;
    color?: string;
}>`
    padding: 6px 12px;
    border-radius: 16px;
    background: ${(props) => props.background || '#c8c8c8'};
    color: ${(props) => props.color || getTextColor(props.background || '#c8c8c8')};
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    font-family: 'roboto';
`;

const CustomDropdown = styled(Dropdown)`
    border: 1px solid var(--gray-300);

    .p-dropdown-label {
        padding: 0 0 0 8px;
        display: flex;
        align-items: center;
    }
`;
