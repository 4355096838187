import React, { useContext, useMemo } from 'react';
import { BackofficeContext } from '@Context/BackofficeContext';
import { Resource } from '@Types/Resource';
import { firestore } from '@Utils/config/firebase';
import { useAsyncMemo } from '@Utils/lifecycle.utils';
import styled from 'styled-components';
import { Module } from '@Types/Module';
import { ReadOnlyEditor } from '@Components/ReadOnlyEditor';
import { Tag } from 'primereact/tag';

const NoImage = styled.div`
    width: 130px;
    height: 75px;
`;

export type ModuleViewerProps = {
    module_id?: string;
    module?: Module;
    title?: string | null;
    extended?: boolean;
};
export const ModuleViewer: React.FC<ModuleViewerProps> = (props) => {
    const { modules } = useContext(BackofficeContext);

    const module = useMemo(() => {
        return props.module || modules.find((m) => m.module_id === props.module_id);
    }, [modules, props.module, props.module_id]);

    const resource = useAsyncMemo(async () => {
        if (!module?.cover_image_url) return undefined;
        return firestore.collection<Resource>('resources').get(module.cover_image_url);
    }, [module]);

    const onExternal = () => {
        window.open(`/modules/${module?.module_id}`, '_blank');
    };

    return module ? (
        <div>
            <div className="flex align-items-center">
                <div className="mr-3">
                    {module.cover_image_url && resource ? (
                        <img src={resource.resource_url} style={{ height: 75, width: 'auto' }} alt="Ressource" />
                    ) : (
                        <NoImage className="bg-gray-400" />
                    )}
                </div>
                <div className="flex flex-column gap-1">
                    <div className="flex  he-paragraph--medium align-items-center gap-3">
                        {(module.type === 'quizz' || module.type === 'validating_quizz') &&
                            module.quizz_type !== 'default' && (
                                <Tag className="w-max he-paragraph--regular bg-teal-300 px-2">
                                    {module.quizz_type === 'initial' ? 'Quizz initial' : 'Quizz final'}
                                </Tag>
                            )}
                            {
                                module.type === "audit" && 
                                <Tag className="w-max he-paragraph--regular bg-pink-400 px-2">
                                    Audit {module.audit_type === "initial" ? "initial" : "final"}
                                </Tag>
                            }
                        {props.title || module.title} {props.title && `(${module.title})`}
                        <i className="pi pi-external-link primary-100 cursor-pointer" onClick={onExternal} />
                    </div>
                    <div className="he-paragraph--regular gray-500">
                        Durée estimée : {module.estimated_duration} {module.estimated_duration_unit}
                    </div>
                </div>
            </div>
            {props.extended && <ReadOnlyEditor className="quill-custom mt-3" content={module.description || null} />}
        </div>
    ) : (
        <div>Aucun module trouvé pour l'identifiant {props.module_id}</div>
    );
};
