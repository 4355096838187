import { CustomDialog } from '@Components/CustomDialog';
import { CustomFormDropdown } from '@Components/CustomFormDropdown';
import { CustomInput } from '@Components/CustomInput';
import { CustomInputNumber } from '@Components/CustomInputNumber';
import { CustomTextEditor } from '@Components/CustomTextEditor';
import { RadioGroup } from '@Components/RadioGroup';
import { UploadButton } from '@Components/UploadButton';
import { usePromise } from '@Hooks/promise';
import { DeleteModule } from '@Pages/pages/components/DeleteModule';
import { AuditModule, Module } from '@Types/Module';
import { deleteResource, uploadResource } from '@Utils/import.utils';
import { useFormik } from 'formik';
import { produce } from 'immer';
import { Button } from 'primereact/button';
import React, { useState } from 'react';
import { getModuleAudit, getModuleQuizz, getValidatingQuizzModule } from './components/module.utils';
import { ConfigCategories } from './ModuleInformation/ConfigCategories';

const durationOptions = [
    {
        label: 'Semaine(s)',
        value: 's',
    },
    {
        label: 'Jours(s)',
        value: 'j',
    },
    {
        label: 'Heure(s)',
        value: 'h',
    },
    {
        label: 'Minute(s)',
        value: 'm',
    },
];

export type ModuleInformationProps = {
    module: Module;
    moduleFormik: ReturnType<typeof useFormik<Module>>;
};
export const ModuleInformation: React.FC<ModuleInformationProps> = ({ module, moduleFormik }) => {
    const [deleteDialogVisible, setDeleteDialogVisible] = useState<boolean>(false);
    const [configDialogVisible, setConfigDialogVisible] = useState<boolean>(false);

    const onRemove = async () => {
        const id = moduleFormik.values.cover_image_url;
        if (id) await deleteResource(id);
        moduleFormik.setFieldValue('cover_image_url', undefined, false);
    };

    const [onUploadImage, loading] = usePromise(async (file: File) => {
        const id = await uploadResource(file);
        moduleFormik.setFieldValue('cover_image_url', id, false);
    });

    function onClearMinimumDuration() {
        moduleFormik.setFieldValue('minimal_duration_unit', null, false);
        moduleFormik.setFieldValue('minimal_duration', null, false);
    }

    const onUpdateType = (type: Module['type']) => {
        switch (type) {
            case 'audit':
                moduleFormik.setValues(
                    produce(moduleFormik.values, (draft) => {
                        return getModuleAudit(draft);
                    })
                );
                break;
            case 'quizz':
                moduleFormik.setValues(
                    produce(moduleFormik.values, (draft) => {
                        return getModuleQuizz(draft);
                    })
                );
                break;
            case 'validating_quizz':
                moduleFormik.setValues(
                    produce(moduleFormik.values, (draft) => {
                        return getValidatingQuizzModule(draft);
                    })
                );
                break;
        }
    };

    return (
        <div>
            {module && deleteDialogVisible && (
                <CustomDialog onHide={() => setDeleteDialogVisible(false)}>
                    <DeleteModule module={module} onQuit={() => setDeleteDialogVisible(false)} />
                </CustomDialog>
            )}
            <div className="he-header--h3 gray-900">Informations du module</div>
            <div className="mt-3">
                <CustomInput field={'title'} formik={moduleFormik} label="Titre du module" />
            </div>
            <div>
                <CustomTextEditor
                    placeholder={'Description du module'}
                    field={'description'}
                    formik={moduleFormik}
                    label="Description du module"
                    className="mt-3"
                />
            </div>
            <UploadButton
                label="Ajouter une image de couverture"
                className="mt-3 w-max"
                buttonClassName="he-button--primary-nf--xs"
                showPreview
                onRemove={onRemove}
                onChange={onUploadImage}
                loading={loading}
                file_id={moduleFormik.values.cover_image_url}
                accept={['image/png', 'image/jpg', 'image/jpeg']}
                icon="pi pi-download"
            />
            <div className="he-header--h3 gray-900 mt-4">Paramètres globaux</div>
            <div className="w-6 mt-2 flex flex-column gap-2">
                <CustomFormDropdown
                    placeholder={'Choisir un type de module'}
                    field="type"
                    onChange={(v) => onUpdateType(v)}
                    value={moduleFormik.values.type}
                    label="Type de module"
                    options={[
                        {
                            label: 'Questionnaire simple',
                            value: 'quizz',
                        },
                        {
                            label: 'Questionnaire bloquant',
                            value: 'validating_quizz',
                        },
                        {
                            label: 'Audit clinique',
                            value: 'audit',
                        },
                    ]}
                />
                {(moduleFormik.values.type === 'quizz' || moduleFormik.values.type === 'validating_quizz') && (
                    <CustomFormDropdown
                        placeholder={'Choisir un type de questionnaire (en cas de FC)'}
                        options={[
                            { label: 'Questionnaire initial', value: 'initial' },
                            { label: 'Questionnaire final', value: 'final' },
                            { label: 'Questionnaire par défaut', value: 'default' },
                        ]}
                        label="Type de questionnaire"
                        formik={moduleFormik}
                        field={'quizz_type'}
                    />
                )}
                {moduleFormik.values.type === 'audit' && (
                    <CustomFormDropdown
                        placeholder={"Choisir un type d'audit clinique"}
                        options={[
                            { label: 'Audit initial', value: 'initial' },
                            { label: 'Audit final', value: 'final' },
                        ]}
                        label="Type d'audit"
                        formik={moduleFormik}
                        field={'audit_type'}
                    />
                )}
                {moduleFormik.values.type === 'validating_quizz' && (
                    <div>
                        <div className="he-header--h3 gray-900 mb-1 mt-3">Configuration questionnaire bloquant</div>
                        <CustomInputNumber
                            field={'threshold'}
                            formik={moduleFormik}
                            label="Choisir un seuil"
                            placeholder="ex. 75%"
                            suffix="%"
                        />
                    </div>
                )}
                {moduleFormik.values.type === 'audit' && (
                    <div>
                        {configDialogVisible && (
                            <CustomDialog onHide={() => setConfigDialogVisible(false)} width={800}>
                                <ConfigCategories
                                    moduleFormik={moduleFormik as ReturnType<typeof useFormik<AuditModule>>}
                                    onClose={() => setConfigDialogVisible(false)}
                                />
                            </CustomDialog>
                        )}
                        <div className="he-header--h3 gray-900 mb-1 mt-3">Configuration Audit clinique</div>
                        <div className="grid">
                            <div className="col-12">
                                <CustomInputNumber
                                    field={'threshold'}
                                    formik={moduleFormik}
                                    label="Choisir un minimum de réussite"
                                    placeholder="ex. 75%"
                                    suffix="%"
                                />
                            </div>
                            <div className="col-6">
                                <CustomInputNumber
                                    field={'min_records'}
                                    formik={moduleFormik}
                                    label="Nombre minimum de dossier"
                                    max={moduleFormik.values.max_records}
                                    min={10}
                                />
                            </div>
                            <div className="col-6">
                                <CustomInputNumber
                                    field={'max_records'}
                                    formik={moduleFormik}
                                    label="Nombre maximum de dossier"
                                    min={moduleFormik.values.min_records}
                                />
                            </div>
                            <div className="col-6 flex align-items-center">
                                <span className="he-paragraph--regular gray-700 text-center w-full">
                                    {moduleFormik.values.categories.length === 0 ? (
                                        <span className="red-100">Aucune thématique configurée</span>
                                    ) : (
                                        `${moduleFormik.values.categories.length} thématique(s) configurée(s)`
                                    )}
                                </span>
                            </div>
                            <div className="col-6">
                                <Button
                                    className="he-button--secondary-variant--md w-full"
                                    onClick={() => setConfigDialogVisible(true)}
                                >
                                    Configurer les catégories
                                    <i className="pi pi-cog" />
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className="he-header--h3 gray-900 mt-4">Durée estimée</div>
            <div className="w-6 mt-2">
                <CustomInputNumber field={'estimated_duration'} formik={moduleFormik} label="Choisir une durée" />
                <RadioGroup
                    className="mt-2"
                    options={durationOptions}
                    name={'estimated_duration_unit'}
                    onChange={(v) => moduleFormik.setFieldValue('estimated_duration_unit', v, false)}
                    value={moduleFormik.values.estimated_duration_unit}
                />
            </div>
            <div className="he-header--h3 gray-900 mt-4 flex">
                Durée minimum
                <div>
                    <i className="pi pi-trash color-red ml-2" onClick={onClearMinimumDuration} />
                </div>
            </div>
            <div className="w-6 mt-2">
                <CustomInputNumber field={'minimal_duration'} formik={moduleFormik} label="Choisir une durée" />
                <RadioGroup
                    className="mt-2"
                    options={durationOptions}
                    name={'minimal_duration_unit'}
                    onChange={(v) => {
                        if (moduleFormik.values.minimal_duration == null)
                            moduleFormik.setFieldValue('minimal_duration', 0, false);
                        moduleFormik.setFieldValue('minimal_duration_unit', v, false);
                    }}
                    value={moduleFormik.values.minimal_duration_unit}
                />
            </div>
            <div className="mt-5">
                <Button
                    className="he-button--primary-nfb--xs"
                    icon="pi pi-trash"
                    label="Supprimer le module"
                    onClick={() => setDeleteDialogVisible(true)}
                />
            </div>
        </div>
    );
};
