import { CustomTabView } from '@Components/CustomTabView';
import { Line } from '@Components/UI/Line';
import { AuditModule } from '@Types/Module';
import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import React, { useState } from 'react';
import { Categories } from './components/Categories';
import { AssociationCategorie } from './components/AssociationCategorie';

type Props = {
    moduleFormik: ReturnType<typeof useFormik<AuditModule>>;
    onClose: () => void;
};
export const ConfigCategories: React.FC<Props> = (props) => {
    const [selectedTab, setSelectedTab] = useState<number>(0);

    return (
        <div>
            <div className="he-header--h3 gray-900 mb-4 flex align-items-center">
                Catégories : {props.moduleFormik.values.title}
            </div>
            <CustomTabView
                selected={selectedTab}
                style={{ marginBottom: 14 }}
                onChange={(t) => setSelectedTab(t)}
                items={[
                    {
                        label: 'Catégories',
                    },
                    {
                        label: 'Associations',
                    },
                ]}
            />
            {selectedTab === 0 && (
                <Categories
                    module={props.moduleFormik.values}
                    moduleFormik={props.moduleFormik}
                    categories={props.moduleFormik.values.categories}
                    setCategories={(categories) => props.moduleFormik.setFieldValue('categories', categories)}
                />
            )}
            {selectedTab === 1 && (
                <AssociationCategorie
                    module={props.moduleFormik.values}
                    categories={props.moduleFormik.values.categories}
                    setCategories={(categories) => props.moduleFormik.setFieldValue('categories', categories)}
                />
            )}
            <Line height={1} className="my-4 bg-gray-200" />
            <div className="flex justify-content-end mt-4">
                <Button className="he-button--secondary--md" label="Terminer" onClick={props.onClose} />
            </div>
        </div>
    );
};
