import { AuditModule, BaseModule, QuizzModule, ValidatingQuizzModule } from '@Types/Module';
import { DateTime } from 'luxon';

export const getModuleQuizz: (module: BaseModule) => QuizzModule = (module: BaseModule) => ({
    cover_image_url: module.cover_image_url,
    created_at: module.created_at,
    estimated_duration: module.estimated_duration,
    estimated_duration_unit: module.estimated_duration_unit,
    minimal_duration: module.minimal_duration,
    minimal_duration_unit: module.minimal_duration_unit,
    module_id: module.module_id,
    title: module.title,
    updated_at: DateTime.now().toISO(),
    description: module.description,
    activities: module.activities,
    type: 'quizz',
    quizz_type: 'default',
});

export const getValidatingQuizzModule: (module: BaseModule) => ValidatingQuizzModule = (module: BaseModule) => ({
    cover_image_url: module.cover_image_url,
    created_at: module.created_at,
    estimated_duration: module.estimated_duration,
    estimated_duration_unit: module.estimated_duration_unit,
    minimal_duration: module.minimal_duration,
    minimal_duration_unit: module.minimal_duration_unit,
    module_id: module.module_id,
    title: module.title,
    updated_at: DateTime.now().toISO(),
    description: module.description,
    activities: module.activities,
    type: 'validating_quizz',
    quizz_type: 'default',
    threshold: 50,
});

export const getModuleAudit: (module: BaseModule) => AuditModule = (module: BaseModule) => ({
    cover_image_url: module.cover_image_url,
    created_at: module.created_at,
    estimated_duration: module.estimated_duration,
    estimated_duration_unit: module.estimated_duration_unit,
    minimal_duration: module.minimal_duration,
    minimal_duration_unit: module.minimal_duration_unit,
    module_id: module.module_id,
    title: module.title,
    updated_at: DateTime.now().toISO(),
    description: module.description,
    activities: module.activities,
    type: 'audit',
    audit_type: 'initial',
    categories: [],
    max_records: 10,
    min_records: 10,
    threshold: 50,
});
