import { Session, SessionParticipant, VirtualMeeting } from "@Types/Session";
import { AuditRecord, UserProgressItem } from "@Types/User";
import { firestore } from "@Utils/config/firebase";
import { collection, collectionGroup, getDocs, onSnapshot, query, where } from "firebase/firestore";
import { groupBy } from "lodash";
import React, {useCallback, useEffect, useState} from "react";

export interface SessionContextI {
	participants: SessionParticipant[],
    virtualClasses: VirtualMeeting[],
    usersProgress: {[user_id: string]: UserProgressItem[]},
    usersRecords: {[user_id: string]: AuditRecord[]},
    loading: boolean,
    reloadProgress: () => void
}

export const SessionContext = React.createContext<SessionContextI>({
	participants: [],
    virtualClasses: [],
    usersProgress: {},
    usersRecords: {},
    loading: false,
    reloadProgress: () => {}
});

export const SessionProvider: React.FC<React.PropsWithChildren<{session: Session}>> = (props) => {

	const [participants, setParticipants] = useState<SessionParticipant[]>([]);
	const [virtualClasses, setVirtualClasses] = useState<VirtualMeeting[]>([]);

    const [loadingParticipants, setLoadingParticipants] = useState<boolean>(false);
    const [loadingClasses, setLoadingClasses] = useState<boolean>(false);

	const [loadingProgress, setloadingProgress] = useState<boolean>(false);
    const [loadingRecords, setloadingRecords] = useState<boolean>(false);

	const [usersProgress, setUsersProgress] = useState<{[user_id: string]: UserProgressItem[]}>({});
    const [usersRecords, setUsersRecords] = useState<{[user_id: string]: AuditRecord[]}>({});

    const reloadProgress = useCallback(() => {
        setloadingProgress(true);
        setloadingRecords(true);

        getDocs(query(collectionGroup(firestore.db, "progress"), where("session_id", "==", props.session.session_id))).then((snap) => {
            const groupedProgress = groupBy(
                    snap.docs.map(doc => doc.data() as UserProgressItem)
            , "user_id");
            setUsersProgress(groupedProgress);
        }).finally(() => setloadingProgress(false));

        getDocs(query(collectionGroup(firestore.db, "records"), where("session_id", "==", props.session.session_id))).then((snap) => {
            const groupedRecords = groupBy(
                    snap.docs.map(doc => doc.data() as AuditRecord)
            , "user_id");
            setUsersRecords(groupedRecords);
        }).finally(() => setloadingRecords(false));

    }, [props.session.session_id]);

	useEffect(() => {
        reloadProgress();
	}, [reloadProgress]);

    useEffect(() => {
        setLoadingParticipants(true);
        setLoadingClasses(true);

        const participantUnsub = onSnapshot(collection(firestore.db, "formations", props.session.formation_id ,"sessions", props.session.session_id, "participants"), participantSnapshot => {
            setParticipants( participantSnapshot.docs.map(p => p.data() as SessionParticipant))
            setLoadingParticipants(false)
        }, error => {
            setLoadingParticipants(false)
        });

        const virtualClassUnsub = onSnapshot(collection(firestore.db, "formations", props.session.formation_id, "sessions", props.session.session_id, "virtual_classes"), meetingRef => {
            setVirtualClasses( meetingRef.docs.map(p => p.data() as VirtualMeeting))
            setLoadingClasses(false)
        }, error => {
            setLoadingClasses(false)
        });

        return () => {
            participantUnsub();
            virtualClassUnsub();
        }
    }, [props.session.session_id])

    return <SessionContext.Provider value={{
        participants,
        virtualClasses,
        usersProgress,
        loading: loadingParticipants || loadingClasses || loadingProgress || loadingRecords,
        usersRecords,
        reloadProgress
    }}>
        {props.children}
    </SessionContext.Provider>;
}
