import React, { useMemo } from 'react';
import { Module } from '@Types/Module';
import { useFormik } from 'formik';
import { CustomFormDropdown } from '@Components/CustomFormDropdown';
import {
    Activity,
    ActivityType,
    CourseActivity,
    FreeTextActivity,
    QuizzActivity,
    TCSActivity,
    VideoActivity,
} from '@Types/Activity';
import { CustomInput } from '@Components/CustomInput';
import { CourseActivityEditor } from '@Pages/pages/Modules/EditModule/ActivityInformation/CourseActivityEditor';
import { Button } from 'primereact/button';
import { CustomInputNumber } from '@Components/CustomInputNumber';
import { VideoActivityEditor } from '@Pages/pages/Modules/EditModule/ActivityInformation/VideoActivityEditor';
import { QuizzActivityEditor } from '@Pages/pages/Modules/EditModule/ActivityInformation/QuizzActivityEditor';
import { TcsActivityEditor } from '@Pages/pages/Modules/EditModule/ActivityInformation/TcsActivityEditor';
import { FreeTextActivityEditor } from '@Pages/pages/Modules/EditModule/ActivityInformation/FreeTextActivityEditor';

const activityOptions: { label: string; value: ActivityType }[] = [
    {
        label: 'Cours',
        value: 'course',
    },
    {
        label: 'Vidéo',
        value: 'video',
    },
    {
        label: 'QCM',
        value: 'mcq',
    },
    {
        label: 'QCU',
        value: 'ucq',
    },
    {
        label: 'Réponse libre',
        value: 'freetext',
    },
    {
        label: 'Test de Concordance de Script (TCS)',
        value: 'tcs',
    },
];

const courseModel: CourseActivity = {
    content: 'contenu',
};

const videoModel: VideoActivity = {
    video_link: undefined,
};

const quizzModel: QuizzActivity = {
    cover_image_url: [null, null, null, null, null],
    cover_video_url: [null, null, null, null, null],
    answers: [],
    question: '',
    is_response_img: false,
};

const freeTextModel: FreeTextActivity = {
    question: '',
    cover_image_url: [null, null, null, null, null],
    cover_video_url: [null, null, null, null, null],
    keywords: [],
};

const tcsModel: TCSActivity = {
    hypothesis: [],
    cover_image_url: [null, null, null, null, null],
    cover_video_url: [null, null, null, null, null],
    description: '',
};

export type ActivityInformationProps = {
    module: Module;
    activity: Activity;
    moduleFormik: ReturnType<typeof useFormik<Module>>;
    setOrder: (activity_id: string, num: number) => void;
    activityFormik: ReturnType<typeof useFormik<Activity>>;
    onDeleteActivity: (activity_id: string) => void;
};
export const ActivityInformation: React.FC<ActivityInformationProps> = ({
    moduleFormik,
    activity,
    setOrder,
    activityFormik,
    onDeleteActivity,
}) => {
    const onActivityTypeChange = (type: ActivityType) => {
        const applyModel = function <T>(model: T) {
            activityFormik.setValues(
                {
                    activity_id: activityFormik.values.activity_id,
                    title: activityFormik.values.title,
                    type: activityFormik.values.type,
                    ...model,
                },
                false
            );
        };

        switch (type) {
            case 'course':
                applyModel(courseModel);
                break;
            case 'video':
                applyModel(videoModel);
                break;
            case 'mcq':
            case 'ucq':
                applyModel(quizzModel);
                break;
            case 'freetext':
                applyModel(freeTextModel);
                break;
            case 'tcs':
                applyModel(tcsModel);
                break;
            default:
                throw Error('Bad type');
        }
        activityFormik.setFieldValue('type', type, false);
    };

    const currentOrder = useMemo(() => {
        return moduleFormik.values.activities.findIndex((a) => a.activity_id === activity.activity_id) + 1;
    }, [activity, moduleFormik]);

    return (
        <div className="w-full">
            <div className="he-header--h3 gray-900">Modifier l'activité</div>
            <div className="he-paragraph--medium gray-900 mt-4"> Information de l'activité</div>
            <div className="flex w-6 mt-3">
                <CustomInputNumber
                    value={currentOrder || 1}
                    onChange={(o) => activity.activity_id && setOrder(activity.activity_id, o || 0)}
                    label="position"
                    className="mr-3 w-3"
                />
                <CustomFormDropdown
                    value={activityFormik.values.type}
                    onChange={onActivityTypeChange}
                    options={activityOptions}
                    label={'Type'}
                    className="w-9"
                />
            </div>
            <div className="mt-3">
                <CustomInput field={'title'} formik={activityFormik} label={"Titre de l'activité"} />
            </div>
            {activityFormik.values.type === 'course' && (
                <CourseActivityEditor formik={activityFormik} key={activityFormik.values.activity_id} />
            )}
            {activityFormik.values.type === 'video' && (
                <VideoActivityEditor formik={activityFormik} key={activityFormik.values.activity_id} />
            )}
            {(activityFormik.values.type === 'mcq' || activityFormik.values.type === 'ucq') && (
                <QuizzActivityEditor
                    formik={activityFormik}
                    key={activityFormik.values.activity_id}
                    module={moduleFormik.values}
                />
            )}
            {activityFormik.values.type === 'tcs' && (
                <TcsActivityEditor formik={activityFormik} key={activityFormik.values.activity_id} />
            )}
            {activityFormik.values.type === 'freetext' && (
                <FreeTextActivityEditor formik={activityFormik} key={activityFormik.values.activity_id} />
            )}
            <Button
                className="he-button--secondary-nfb--xs mt-4"
                label="Supprimer l'activité"
                icon="pi pi-trash"
                onClick={() => activity.activity_id && onDeleteActivity(activity.activity_id)}
            />
        </div>
    );
};
