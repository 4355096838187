import { CustomInput } from '@Components/CustomInput';
import { Line } from '@Components/UI/Line';
import { AuditModule, AuditModuleCategory } from '@Types/Module';
import { getTextColor } from '@Utils/misc.utils';
import { sanitizeString } from '@Utils/string.utils';
import { useFormik } from 'formik';
import { PrimeIcons } from 'primereact/api';
import { Button } from 'primereact/button';
import { ColorPicker } from 'primereact/colorpicker';
import { InputSwitch } from 'primereact/inputswitch';
import { classNames } from 'primereact/utils';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { v4 } from 'uuid';

type Props = {
    module: AuditModule;
    moduleFormik: ReturnType<typeof useFormik<AuditModule>>;
    categories: AuditModuleCategory[];
    setCategories: (categories: AuditModuleCategory[]) => void;
};
export const Categories: React.FC<Props> = (props) => {
    const [selection, setSelection] = useState<AuditModuleCategory | null>(null);
    const [showDetails, setShowDetails] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');

    const filteredCategories = useMemo(() => {
        if (!search.trim()) return props.categories;
        return props.categories.filter((category) => {
            const sanitizedSearch = sanitizeString(search.toLowerCase()).trim();
            const sanitizedLabel = sanitizeString(category.label.toLowerCase()).trim();
            return sanitizedLabel.includes(sanitizedSearch);
        });
    }, [props.categories, search]);

    const onNewCategory = async () => {
        props.setCategories([
            ...props.categories,
            {
                category_id: v4(),
                label: 'Nouvelle catégorie',
                color: '#c8c8c8',
                success_text: '',
                failure_text: '',
                activity_ids: [],
            },
        ]);
    };

    const onDeleteCategory = async (category: AuditModuleCategory) => {
        props.setCategories(props.categories.filter((c) => c.category_id !== category.category_id));
    };

    const onSaveTheme = async (category: AuditModuleCategory) => {
        props.setCategories(
            props.categories.map((c) => {
                if (c.category_id === category.category_id) {
                    return category;
                }
                return c;
            })
        );
        setSelection(null);
    };

    /* EFFECTS */

    /* EVENT HANDLERS */
    /* RENDERING */

    return (
        <>
            <div className="card p-2 border-round-md bg-gray-100 flex align-items-center w-full gap-3 mt-2">
                <CustomInput
                    icon="pi pi-search"
                    placeholder="Rechercher une catégorie"
                    value={search}
                    onChange={setSearch}
                />
                <label className="he-paragraph--medium gray-600 flex align-items-center gap-2">
                    Voir le détails
                    <InputSwitch checked={showDetails} onChange={(e) => setShowDetails(e.value)} />
                </label>
                <Button className="he-button--primary--md ml-auto" onClick={onNewCategory}>
                    Nouvelle catégorie <i className={PrimeIcons.PLUS} />
                </Button>
            </div>
            {filteredCategories.map((category, i) => {
                const errors = props.moduleFormik.errors.categories?.[i];
                console.log('errors', errors);
                return (
                    <CategoryContainer key={category.category_id}>
                        <div className="w-full flex gap-2 align-items-center">
                            {selection && selection.category_id === category.category_id ? (
                                <>
                                    <div className="flex align-items-center">
                                        <CustomColorPicker
                                            value={selection.color?.replace('#', '')}
                                            onChange={(e) =>
                                                setSelection({
                                                    ...selection,
                                                    color: typeof e.value === 'string' ? `#${e.value}` : '#c8c8c8',
                                                })
                                            }
                                        />
                                    </div>
                                    <CustomInput
                                        value={selection.label}
                                        placeholder={'Nom de la catégorie'}
                                        onChange={(str) => {
                                            setSelection({ ...selection, label: str });
                                        }}
                                    />

                                    <Button
                                        className="he-button--secondary-nf--xs ml-auto"
                                        onClick={() => onSaveTheme(selection)}
                                    >
                                        Enregistrer
                                        <i className={classNames(PrimeIcons.SAVE)} />
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <ThemeTag background={category.color} color={getTextColor(category.color)}>
                                        {category.label}
                                    </ThemeTag>
                                    <span className="he-paragraph--small-bold gray-600">
                                        {`${category.activity_ids.length} ${
                                            category.activity_ids.length > 1
                                                ? 'activités associées'
                                                : 'activité associée'
                                        }`}
                                    </span>
                                    <i
                                        className={classNames(PrimeIcons.PENCIL, 'ml-auto', 'cursor-pointer', 'p-2')}
                                        onClick={() => setSelection(category)}
                                    />
                                    <i
                                        className={classNames(PrimeIcons.TRASH, 'color-red', 'cursor-pointer', 'p-2')}
                                        onClick={() => onDeleteCategory(category)}
                                    />
                                </>
                            )}
                        </div>
                        {(showDetails || selection?.category_id === category.category_id) &&
                            (!selection || selection?.category_id !== category.category_id ? (
                                <>
                                    <Line height={1} style={{ background: '#d9dade' }} className="w-12 mb-4 my-3" />
                                    <div className="w-full flex flex-column align-items-center gap-3">
                                        <div className="w-full flex align-items-start gap-4">
                                            <div className="w-full flex flex-column pt-2 gap-1">
                                                <span>Réussite</span>
                                                <TextAreaDisplay className="gray-600">
                                                    {category?.success_text}
                                                </TextAreaDisplay>
                                            </div>
                                            <div className="w-full flex flex-column pt-2 gap-1">
                                                <span>Échec</span>
                                                <TextAreaDisplay className="gray-600">
                                                    {category?.failure_text}
                                                </TextAreaDisplay>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <Line height={1} style={{ background: '#d9dade' }} className="w-12 mb-4 my-3" />
                                    <div className="w-full flex align-items-start gap-4">
                                        <CustomInput
                                            label={`Texte explicatif échec (< ${props.module.threshold}%)`}
                                            value={category.failure_text}
                                            type={'textarea'}
                                            placeholder={
                                                'Texte à afficher lorsque le résultat est inférieur à ce seuil'
                                            }
                                            customError={typeof errors === 'string' ? errors : errors?.failure_text}
                                            onChange={(str) => {
                                                if (selection) {
                                                    setSelection({ ...selection, failure_text: str });
                                                }
                                                props.setCategories(
                                                    props.categories.map((c) => {
                                                        if (c.category_id === category.category_id) {
                                                            return { ...c, failure_text: str };
                                                        }
                                                        return c;
                                                    })
                                                );
                                            }}
                                            className={'w-full'}
                                            height={128}
                                        />
                                        <CustomInput
                                            label={`Texte explicatif réussite (>= ${props.module.threshold}%)`}
                                            value={category.success_text}
                                            type={'textarea'}
                                            placeholder={
                                                'Texte à afficher lorsque le résultat est supérieur ou égal à ce seuil'
                                            }
                                            customError={typeof errors === 'string' ? errors : errors?.success_text}
                                            onChange={(str) => {
                                                if (selection) {
                                                    setSelection({ ...selection, success_text: str });
                                                }
                                                props.setCategories(
                                                    props.categories.map((c) => {
                                                        if (c.category_id === category.category_id) {
                                                            return { ...c, success_text: str };
                                                        }
                                                        return c;
                                                    })
                                                );
                                            }}
                                            className={'w-full'}
                                            height={128}
                                        />
                                    </div>
                                </>
                            ))}
                    </CategoryContainer>
                );
            })}
        </>
    );
};
const CategoryContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    border-radius: 10px;
    background: var(--gray-50);
    border: 1px solid var(--gray-200);
    width: 100%;
    margin-top: 8px;
`;

const ThemeTag = styled.div<{
    background?: string;
    color?: string;
}>`
    padding: 8px 16px;
    border-radius: 10px;
    background: ${(props) => props.background || '#c8c8c8'};
    color: ${(props) => props.color || '#000000'};
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    font-family: 'roboto';
`;

const CustomColorPicker = styled(ColorPicker)`
    --picker-size: 24px;
    width: var(--picker-size);
    height: var(--picker-size);
    border-radius: 50%;
    input {
        width: var(--picker-size);
        height: var(--picker-size);
        border-radius: 50%;
    }
`;

const TextAreaDisplay = styled.div`
    line-height: 20px;
    font-size: 14px;
    font-family: 'roboto';
    font-weight: 400;
    white-space: pre-line;
    width: 100%;
`;
