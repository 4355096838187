import React, {useEffect, useMemo, useRef, useState} from "react";
import {Token, User} from "@Types/User";
import {useToast} from "@Hooks/toast";
import {usersRef} from "@Utils/config/firebase";
import {useInView} from "framer-motion";
import {DateTime} from "luxon";
import { copyToClipboard } from '@Utils/misc.utils';
import { env } from "src/env";

export type UserCopyButtonProps = {
	user: User,
}
export const UserCopyButton: React.FC<UserCopyButtonProps> = (props) => {

	const [token, setToken] = useState<string | null>(null);
	const [tokenReset, setTokenReset] = useState<string | null>(null);

	const {success} = useToast()

	const ref = useRef<HTMLDivElement>(null);
	const isInView = useInView(ref);

	useEffect(() => {
		console.log(isInView);
		if (isInView) {
			usersRef.collection<Token>(props.user.user_id, "tokens").get().then(res => {
				for (const doc of res.docs) {
					const data = doc.data();
					console.log(data);
					if (data.code && data.source === "user-confirm") {
						setToken(data.code)
					} else if (data.code && data.source === "password-reset" && data.expires_at && DateTime.fromISO(data.expires_at).diffNow("hours").as("hours") > 0) {
						setTokenReset(data.code)
					}
				}
			})
		}
	}, [props.user, isInView]);

	const link = useMemo(() => `${props.user.role === "admin" ? env.host : env.lmsHost}/register?code=${token}&email=${props.user.email}`, [props.user, token])
	const linkReset = useMemo(() => `${props.user.role === "admin" ? env.host : env.lmsHost}/reset/${tokenReset}`, [props.user, tokenReset])

	return <div className="flex flex-column" ref={ref}>
		{token ? <div className="he-paragraph--regular color-blue cursor-pointer select-none" onClick={() => copyToClipboard(link).then(() =>success("Lien copié !"))}> Lien d'invitation <i className="pi pi-copy"/> </div> : <div> - </div>}
		{tokenReset ? <div className="mt-2 he-paragraph--regular color-blue cursor-pointer select-none" onClick={() => copyToClipboard(linkReset).then(() =>success("Lien copié !"))} ref={ref}> Lien de réinitialisation <i className="pi pi-copy"/> </div> : <div className={"mt-2"}> - </div>}
	</div>
}
