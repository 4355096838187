import React, { useContext, useMemo, useRef, useState } from 'react';
import { ContentLayout } from '@Pages/pages/layout/ContentLayout';
import { CustomTable } from '@Components/CustomTable';
import { DateTime } from 'luxon';
import { ColumnSortEvent } from 'primereact/column';
import { Module } from '@Types/Module';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { CustomActionDropdown, DropdownOption } from '@Components/CustomActionDropdown';
import { CustomDialog } from '@Components/CustomDialog';
import { DeleteModule } from '@Pages/pages/components/DeleteModule';
import { BackofficeContext } from '@Context/BackofficeContext';
import { Session } from '@Types/Session';
import { useNavigate } from 'react-router-dom';
import { useApi } from '@Hooks/api';
import { usePromise } from '@Hooks/promise';
import { fuzzSearch } from '@Utils/search.utils';
import { TieredMenu } from 'primereact/tieredmenu';

export type ModulesProps = Record<string, never>;
export const Modules: React.FC<ModulesProps> = () => {
    const { modules: allModules, getSessionsOfModule } = useContext(BackofficeContext);

    const modules = useMemo(() => {
        return allModules.map((m) => {
            return {
                ...m,
                sessions: getSessionsOfModule(m.module_id),
            };
        });
    }, [allModules, getSessionsOfModule]);

    const [selection, setSelection] = useState<Module>();

    const ref = useRef<TieredMenu>(null);

    const [deleteVisible, setDeleteVisible] = useState<boolean>(false);

    const navigate = useNavigate();

    const [duplicateModule] = usePromise(
        async (module_id: string) => {
            console.log(module_id);

            return api.module_call_duplicate({
                module_id,
            });
        },
        {
            pending: 'Duplication du module en cours...',
            success: 'Module dupliqué avec succès',
        }
    );

    const moduleActions = useMemo<DropdownOption[]>(
        () => [
            {
                label: 'Modifier le module',
                action: () => {
                    if (selection) navigate(`/modules/${selection.module_id}`);
                },
                icon: 'pi pi-pencil',
            },
            {
                label: 'Dupliquer le module',
                action: () => {
                    if (!selection) return;
                    duplicateModule(selection.module_id);
                },
                icon: 'pi pi-copy',
            },
            {
                label: 'Supprimer le module',
                action: () => {
                    setDeleteVisible(true);
                },
                icon: 'pi pi-trash',
            },
        ],
        [selection]
    );
    const onActionClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, module: Module) => {
        setSelection(module);
        ref.current?.toggle(e);
    };

    const ModuleDropdown = (module: Module) => {
        return (
            ref.current && (
                <div
                    className="he-paragraph--small gray-900 flex align-items-center cursor-pointer"
                    onClick={(e) => {
                        e.stopPropagation();
                        onActionClick(e, module);
                    }}
                >
                    <div className="mr-1">Actions</div>
                    <i className="pi pi-angle-down gray-400" style={{ fontSize: 9 }} />
                </div>
            )
        );
    };

    const api = useApi();

    const [onCreateModule, loading] = usePromise(async () => {
        const res = await api.module_call_create({
            module: {
                module_id: '',
                cover_image_url: null,
                activities: [],
                title: 'Nouveau module',
                created_at: '',
                estimated_duration_unit: 'j',
                type: 'quizz',
                quizz_type: 'default',
                updated_at: '',
                estimated_duration: 0,
                description: undefined,
            },
        });

        if (res.result !== 'ok') throw new Error(res.result);

        navigate(`/modules/${res.module_id}`);
    });

    const [filter, setFilter] = useState<string>('');

    const filtered = useMemo(() => {
        const result = fuzzSearch(modules, filter, ['title'], true);
        return result.sort((a, b) => (a.title < b.title ? -1 : 1));
    }, [filter, modules]);

    return (
        <ContentLayout title={'Gestion des modules'} subtitle={'Vous pouvez gérer vos modules'}>
            {selection && deleteVisible && (
                <CustomDialog onHide={() => setDeleteVisible(false)}>
                    <DeleteModule module={selection} onQuit={() => setDeleteVisible(false)} />
                </CustomDialog>
            )}
            <CustomActionDropdown options={moduleActions} ref={ref} />
            <CustomTable
                dataKey={'module_id'}
                rowHover
                rowClassName="cursor-pointer"
                onRowClick={{
                    click: (item) => navigate(`/modules/${item.module_id}`),
                    ctrlClick: (item) => window.open(`/modules/${item.module_id}`, '_blank'),
                }}
                columns={[
                    {
                        header: 'Titre du module',
                        filter: {
                            type: 'sort',
                            field: 'title',
                        },
                        body: (module) => <div>{module.title}</div>,
                    },
                    {
                        header: 'Nombre de sessions',
                        filter: {
                            type: 'sort',
                            field: 'sessions',
                        },
                        sortFunction(e: ColumnSortEvent) {
                            return (e.data as Array<Module & { sessions: Session[] }>).sort(
                                (a, b) => (e.order || 0) * (a['sessions'].length - b['sessions'].length)
                            );
                        },
                        body: (module) => <div>{module.sessions.length}</div>,
                    },
                    {
                        header: "Nombre d'activités",
                        filter: {
                            type: 'sort',
                            field: 'activities',
                        },
                        sortFunction(e: ColumnSortEvent) {
                            return e.data.sort(
                                (a: Module, b: Module) =>
                                    (e.order || 0) * (a['activities'].length - b['activities'].length)
                            );
                        },
                        body: (module) => <div>{module.activities.length}</div>,
                    },
                    {
                        header: 'Créé le',
                        filter: {
                            type: 'sort',
                            field: 'created_at',
                        },
                        body: (module) => <div>{DateTime.fromISO(module.created_at).toFormat('dd/MM/yyyy')}</div>,
                    },
                    {
                        header: 'Modifié le',
                        filter: {
                            type: 'sort',
                            field: 'updated_at',
                        },
                        body: (module) => <div>{DateTime.fromISO(module.updated_at).toFormat('dd/MM/yyyy')}</div>,
                    },
                    {
                        body: ModuleDropdown,
                    },
                ]}
                values={filtered}
                header={{
                    title: 'Liste des modules',
                    actions: [
                        <span className="p-input-icon-left mr-3">
                            <i className="pi pi-search" />
                            <InputText
                                placeholder="Rechercher un module"
                                value={filter}
                                onChange={(e) => setFilter(e.target.value)}
                            />
                        </span>,
                        <Button
                            label="Créer un module"
                            icon="pi pi-plus"
                            className={'he-button--primary--md'}
                            onClick={onCreateModule}
                            loading={loading}
                        />,
                    ],
                }}
            />
        </ContentLayout>
    );
};
