import { CustomActionDropdown } from '@Components/CustomActionDropdown';
import { CustomDialog } from '@Components/CustomDialog';
import { CustomTable } from '@Components/CustomTable';
import { BackofficeContext } from '@Context/BackofficeContext';
import { useApi } from '@Hooks/api';
import { useUser } from '@Hooks/firebase';
import { usePromise } from '@Hooks/promise';
import { UpdateEndDate } from '@Pages/pages/Sessions/pages/components/UpdateEndDate';
import { AnswerDetails } from '@Pages/pages/components/AnswerDetails';
import { ArchiveSession } from '@Pages/pages/components/ArchiveSession';
import { BanUser } from '@Pages/pages/components/BanUser';
import { Formation } from '@Types/Formation';
import { Session, SessionParticipant, SessionTimeReport } from '@Types/Session';
import { AuditRecord, User, UserProgressItem } from '@Types/User';
import { firestore } from '@Utils/config/firebase';
import { downloadFromURL, downloadUint8 } from '@Utils/download.utils';
import { fuzzSearch } from '@Utils/search.utils';
import { collection, collectionGroup, getDocs, query, where } from 'firebase/firestore';
import _ from 'lodash';
import { DateTime, Duration } from 'luxon';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tag } from 'primereact/tag';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { TieredMenu } from 'primereact/tieredmenu';

export type ExtendedSession = Session & {
    formation: Formation;
    participant: SessionParticipant & {
        elapsed_time?: number;
        last_activity?: number;
        progress?: number;
        timeReport?: SessionTimeReport;
        session_id?: string;
    };
};

export type UserSessionsProps = {
    user: User;
};
export const UserSessions: React.FC<UserSessionsProps> = (props) => {
    const { formations, sessions, loading } = useContext(BackofficeContext);
    const [archiveVisible, setArchiveVisible] = useState<boolean>(false);

    const ref = useRef<TieredMenu>(null);
    const groupedRef = useRef<TieredMenu>(null);
    const api = useApi();

    const [selection, setSelection] = useState<ExtendedSession>();
    const [groupedSelection, setGroupedSelection] = useState<ExtendedSession[]>([]);

    const [banUserVisible, setBanUserVisible] = useState<boolean>(false);
    const [answerDetailVisible, setAnswerDetailVisible] = useState<boolean>(false);
    const [endDateUpdateVisible, setEndDateUpdateVisible] = useState<boolean>(false);

    const { meta } = useUser(true);

    const [loadingSessions, setloadingSessions] = useState<boolean>(false);
    const [extendedSession, setExtendedSession] = useState<ExtendedSession[]>([]);

    const [userProgress, setUserProgress] = useState<{ [k: string]: UserProgressItem[] }>({});
    const [userRecords, setUserRecords] = useState<{ [k: string]: AuditRecord[] }>({});
    const [loadingProgress, setLoadingProgress] = useState<boolean>(false);
    const [loadingRecords, setLoadingRecords] = useState<boolean>(false);

    const getUserSessionsExtended = async (user_id: string) => {
        const participantsRefs = await getDocs(
            query(collectionGroup(firestore.db, 'participants'), where('participant_id', '==', user_id))
        );
        return participantsRefs.docs
            .map((doc) => {
                const participant = doc.data() as SessionParticipant;
                const session_id = doc.ref.parent.parent?.id;
                const session = sessions.find((s) => s.session_id === session_id);
                if (!session) return null;
                const formation = formations.find((f) => f.formation_id === session.formation_id);
                if (!formation) return null;
                console.log(session.session_id);
                return {
                    ...session,
                    formation,
                    participant,
                };
            })
            .filter((s) => s !== null) as ExtendedSession[];
    };

    const [timeReports, setTimeReports] = useState<SessionTimeReport[]>([]);
    const [loadingTimeReport, setLoadingTimeReport] = useState<boolean>(false);

    const loadTimeReport = () => {
        setLoadingTimeReport(true);

        return api
            .session_call_userTimeReport({
                user_id: props.user.user_id,
            })
            .then((res) => {
                if (res.result === 'ok') {
                    setTimeReports(res.sessions);
                }
            })
            .finally(() => setLoadingTimeReport(false));
    };

    useEffect(() => {
        setloadingSessions(true);
        setLoadingProgress(true);
        setLoadingRecords(true);

        getUserSessionsExtended(props.user.user_id)
            .then(setExtendedSession)
            .finally(() => setloadingSessions(false));

        getDocs(collection(firestore.db, 'users', props.user.user_id, 'progress'))
            .then((snapshot) => {
                setUserProgress(
                    _.groupBy(
                        snapshot.docs.map((doc) => doc.data() as UserProgressItem),
                        'session_id'
                    )
                );
                setLoadingProgress(false);
            })
            .finally(() => setLoadingProgress(false));
        getDocs(collection(firestore.db, 'users', props.user.user_id, 'records'))
            .then((snapshot) => {
                setUserRecords(
                    _.groupBy(
                        snapshot.docs.map((doc) => doc.data() as AuditRecord),
                        'session_id'
                    )
                );
                setLoadingRecords(false);
            })
            .finally(() => setLoadingRecords(false));
    }, [props.user.user_id, sessions, formations]);

    const [onUpdateAccess] = usePromise(
        async (session: ExtendedSession, force?: boolean) => {
            await api.participant_call_update({
                participant: {
                    ...session.participant,
                    attestation_access: force || !session.participant.attestation_access,
                },
                session_id: session.session_id,
            });
        },
        {
            pending: "Mise à jour de l'accès en cours...",
            success: "Les permissions d'accès ont étés mis à jour",
        }
    );

    const [downloadUserAttestation] = usePromise(
        async (sessions: ExtendedSession[], format: 'pdf' | 'docx') => {
            const res = await api.session_call_getAttestation({
                queries: sessions.map((s) => ({
                    session_id: s.session_id,
                    user_ids: [s.participant.participant_id],
                })),
                format,
            });

            if (res.result !== 'ok') throw new Error(res.result);

            if (res.mode === 'single') {
                const formation = formations.find((f) => f.formation_id === sessions[0].formation_id);
                if (formation && sessions[0]) {
                    const session = sessions[0];
                    const participant = session.participant;
                    if (format === 'pdf') {
                        await downloadUint8(
                            res.file,
                            `Attestation suivi formation - ${formation.andpc} - Session ${session.session_custom_id} - ${participant.firstname} ${participant.lastname}.pdf`,
                            'application/pdf'
                        );
                    } else {
                        await downloadUint8(
                            res.file,
                            `Attestation suivi formation - ${formation.andpc} - Session ${session.session_custom_id} - ${participant.firstname} ${participant.lastname}.docx`,
                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                        );
                    }
                }
            } else {
                await downloadUint8(
                    res.file,
                    `Attestations suivi formation - ${props.user.lastname} ${props.user.firstname}.zip`,
                    'application/zip'
                );
            }
        },
        {
            pending: 'Génération en cours, cette opération peut prendre du temps...',
            success: 'Génération terminée, le téléchargement va démarrer',
        }
    );

    const [downloadUserHonorAttestation] = usePromise(
        async (sessions: ExtendedSession[]) => {
            const res = await api.pdf_call_downloadAttestationsZip({
                queries: sessions.map((s) => ({
                    session_id: s.session_id,
                    participant_ids: [s.participant.participant_id],
                })),
            });

            if (res.result !== 'ok') throw new Error(res.result);

            // await downloadUint8(res.zip, `Attestations sur l'honneur - ${props.user.lastname} ${props.user.firstname}.zip`, "application/zip");
        },
        {
            pending: 'Génération en cours, cette opération peut prendre du temps...',
            success: 'Génération terminée, le téléchargement va démarrer',
        }
    );

    const [sendUserAttestation] = usePromise(
        async (user_ids: string[], session: ExtendedSession) => {
            await api.session_call_sendAttestation({
                session_id: session.session_id,
                user_ids: user_ids,
            });
        },
        {
            pending: 'Envoi en cours, cette opération peut prendre du temps...',
            success: 'Envoi terminée, le téléchargement va démarrer',
        }
    );

    const [downloadUsersLog] = usePromise(
        async (user_ids: string[], session: ExtendedSession) => {
            const res = await api.session_call_getUserLogs({
                session_id: session.session_id,
                user_ids,
                unit_ids: session.unitsConfig.map((u) => u.unit_id),
            });
            if (res.result !== 'ok') throw new Error(res.result);
            await downloadFromURL(res.url);
        },
        {
            pending: 'Génération des logs en cours, cette opération peut prendre du temps...',
            success: 'Logs créer le téléchargement va démarrer',
        }
    );

    const [groupedAccesUpdate] = usePromise(
        async (sessions: ExtendedSession[], force?: boolean) => {
            for (const session of sessions) {
                await api.participant_call_update({
                    participant: {
                        ...session.participant,
                        attestation_access: force || !session.participant.attestation_access,
                    },
                    session_id: session.session_id,
                });
            }
        },
        {
            pending: 'Mise à jour des accès en cours...',
            success: "Les permissions d'accès ont étés mis à jour",
        }
    );

    const groupedOptions = useMemo(
        () => [
            ...(['author', 'admin'].includes(meta.role)
                ? [
                      {
                          label: "Prolonger l'accès si l'apprenant a terminé",
                          icon: 'pi pi-pencil',
                          action: () => setEndDateUpdateVisible(true),
                      },
                  ]
                : []),
            ...(['author', 'admin'].includes(meta.role)
                ? [
                      {
                          label: 'Débloquer l’accès à l’attestation de suivi DPC',
                          icon: 'pi pi-check-circle',
                          action: () => groupedAccesUpdate(groupedSelection, true),
                      },
                  ]
                : []),
            ...(['author', 'admin'].includes(meta.role)
                ? [
                      {
                          label: 'Télécharger les attestations de suivi DPC .pdf',
                          icon: 'pi pi-download',
                          action: () => {
                              if (groupedSelection.length > 0) downloadUserAttestation(groupedSelection, 'pdf');
                          },
                      },
                      {
                          label: 'Télécharger les attestations de suivi DPC .docx',
                          icon: 'pi pi-download',
                          action: () => {
                              if (groupedSelection.length > 0) downloadUserAttestation(groupedSelection, 'docx');
                          },
                      },
                  ]
                : []),
            ...(['author', 'admin'].includes(meta.role)
                ? [
                      {
                          label: "Télécharger les attestations sur l'honneur",
                          icon: 'pi pi-download',
                          action: () => {
                              if (groupedSelection.length > 0) downloadUserHonorAttestation(groupedSelection);
                          },
                      },
                  ]
                : []),
        ],
        [groupedSelection]
    );

    const options = useMemo(
        () =>
            !selection?.participant.blocked
                ? [
                      ...(['admin', 'author'].includes(meta.role)
                          ? [
                                {
                                    label: "Prolonger l'accès si l'apprenant a terminé",
                                    icon: 'pi pi-pencil',
                                    action: () => setEndDateUpdateVisible(true),
                                },
                            ]
                          : []),
                      ...(['admin', 'author'].includes(meta.role)
                          ? [
                                {
                                    label: "Voir l'historique des réponses",
                                    icon: 'pi pi-eye',
                                    action: () => {
                                        setAnswerDetailVisible(true);
                                    },
                                },
                            ]
                          : []),
                      ...(selection &&
                      selection.participant.attestation_access &&
                      ['admin', 'author'].includes(meta.role)
                          ? [
                                {
                                    label: 'Bloquer l’accès à l’attestation de suivi DPC',
                                    icon: 'pi pi-ban',
                                    action: () => onUpdateAccess(selection),
                                },
                                ...(['admin', 'author'].includes(meta.role)
                                    ? [
                                          {
                                              label: 'Envoyer l’attestation de suivi DPC par mail',
                                              icon: 'pi pi-envelope',
                                              action: () => {
                                                  if (selection)
                                                      sendUserAttestation(
                                                          [selection.participant.participant_id],
                                                          selection
                                                      );
                                              },
                                          },
                                      ]
                                    : []),
                                ...(['admin', 'author'].includes(meta.role)
                                    ? [
                                          {
                                              label: 'Télécharger l’attestation de suivi DPC .pdf',
                                              icon: 'pi pi-download',
                                              action: () => {
                                                  if (selection) downloadUserAttestation([selection], 'pdf');
                                              },
                                          },
                                          {
                                              label: 'Télécharger l’attestation de suivi DPC .docx',
                                              icon: 'pi pi-download',
                                              action: () => {
                                                  if (selection) downloadUserAttestation([selection], 'docx');
                                              },
                                          },
                                      ]
                                    : []),
                            ]
                          : [
                                ...(['admin', 'author'].includes(meta.role)
                                    ? [
                                          {
                                              label: 'Autoriser l’accès à l’attestation de suivi DPC',
                                              icon: 'pi pi-check-circle',
                                              action: () => {
                                                  confirmDialog({
                                                      header: 'Autoriser l’accès à l’attestation de suivi DPC',
                                                      message:
                                                          'Êtes-vous sûr de vouloir donner l’accès à l’attestation de suivi DPC à cet utilisateur ?',
                                                      acceptLabel: 'Autoriser',
                                                      rejectLabel: 'Annuler',
                                                      acceptClassName: 'p-button-warning',
                                                      visible: true,
                                                      accept() {
                                                          selection && onUpdateAccess(selection);
                                                      },
                                                  });
                                              },
                                          },
                                      ]
                                    : []),
                            ]),
                      ...(['admin', 'author'].includes(meta.role)
                          ? [
                                {
                                    label: 'Télécharger les logs',
                                    icon: 'pi pi-download',
                                    action: () => {
                                        if (selection)
                                            downloadUsersLog([selection.participant.participant_id], selection);
                                    },
                                },
                            ]
                          : []),
                      {
                          label: "Bloquer l'apprenant de la session",
                          icon: 'pi pi-ban',
                          action: () => setBanUserVisible(true),
                      },
                  ]
                : [
                      {
                          label: "Débloquer l'apprenant",
                          icon: 'pi pi-check-circle',
                          action: () => setBanUserVisible(true),
                      },
                  ],
        [selection]
    );

    const onActionClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, session: ExtendedSession) => {
        setSelection(session);
        ref.current?.toggle(e);
    };

    const ParticipantDropdown = (session: ExtendedSession) => {
        return (
            ref.current && (
                <div
                    className="he-paragraph--small gray-900 flex align-items-center cursor-pointer"
                    onClick={(e) => {
                        e.stopPropagation();
                        onActionClick(e, session);
                    }}
                >
                    <div className="mr-1">Actions</div>
                    <i className="pi pi-angle-down gray-400" style={{ fontSize: 9 }} />
                </div>
            )
        );
    };

    /* FILTERING */

    const [filter, setFilter] = useState<string>('');

    const [showArchived, setShowArchived] = useState<boolean>(true);

    const filtered = useMemo(() => {
        const items = fuzzSearch(
            extendedSession.filter((s) => showArchived || !s?.archived),
            filter,
            ['session_custom_id', 'formation.title'],
            true
        );
        return items;
    }, [filter, extendedSession, showArchived]);

    const { getUserProgressInSession } = useContext(BackofficeContext);

    const completeSessions = useMemo(() => {
        return filtered
            .filter((s) => !!s)
            .map((s) => ({
                ...s,
                participant: {
                    ...s.participant,
                    last_activity: timeReports.find((t) => t.session_id === s.session_id)?.last_activity,
                    elapsed_time: timeReports.find((t) => t.session_id === s.session_id)?.total,
                    timeReport: timeReports.find((t) => t.session_id === s.session_id),
                    progress: getUserProgressInSession(
                        s!.participant.participant_id,
                        userProgress[s!.session_id] || [],
                        userRecords[s!.session_id] || [],
                        s!.session_id
                    ),
                },
            }));
    }, [filtered, userProgress, getUserProgressInSession, timeReports, userRecords]);

    const navigate = useNavigate();

    return (
        <div className="UserSessions">
            {selection && archiveVisible && (
                <CustomDialog onHide={() => setArchiveVisible(false)}>
                    <ArchiveSession onQuit={() => setArchiveVisible(false)} session={selection} />
                </CustomDialog>
            )}
            <CustomActionDropdown options={options} ref={ref} />
            <CustomActionDropdown options={groupedOptions} ref={groupedRef} />
            {selection?.participant && endDateUpdateVisible && (
                <CustomDialog onHide={() => setEndDateUpdateVisible(false)}>
                    <UpdateEndDate
                        onQuit={() => setEndDateUpdateVisible(false)}
                        session={selection}
                        participants={[selection.participant]}
                    />
                </CustomDialog>
            )}
            {selection?.participant && banUserVisible && (
                <CustomDialog onHide={() => setBanUserVisible(false)}>
                    <BanUser
                        onQuit={() => setBanUserVisible(false)}
                        session_id={selection.session_id}
                        participant={selection.participant}
                    />
                </CustomDialog>
            )}
            {selection && answerDetailVisible && (
                <CustomDialog onHide={() => setAnswerDetailVisible(false)}>
                    <AnswerDetails
                        onQuit={() => setAnswerDetailVisible(false)}
                        session={selection}
                        userProgress={userProgress[selection.session_id]}
                        participant={selection.participant}
                    />
                </CustomDialog>
            )}
            <ConfirmDialog />
            <div className="my-3 w-full flex justify-content-end align-items-center">
                Afficher les sessions archivées
                <InputSwitch className="mx-3" checked={showArchived} onChange={(e) => setShowArchived(e.value)} />
            </div>
            <CustomTable
                className="mt-3"
                dataKey={'session_id'}
                onRowClick={{
                    click: (row) => navigate(`/sessions/${row.session_id}`),
                    ctrlClick: (row) => window.open(`/sessions/${row.session_id}`, '_blank'),
                }}
                loading={loading || loadingSessions || loadingProgress || loadingRecords}
                rowHover
                rowClassName="cursor-pointer"
                selection={groupedSelection}
                onSelectionChange={(sel) => {
                    setGroupedSelection(sel);
                }}
                columns={[
                    {
                        header: 'N°',
                        size: 80,
                        body: (session) => <div>{session.session_custom_id}</div>,
                    },
                    {
                        header: 'Nom de la session',
                        size: 450,
                        filter: {
                            type: 'select',
                            filterOptions: (items) =>
                                _.uniqBy<{ value: any; label: string }>(
                                    items?.map((item) => ({
                                        label:
                                            formations.find((f) => f.formation_id === item.formation_id)?.title || '',
                                        value: item.formation_id,
                                    })),
                                    'value'
                                ),
                            field: 'formation_id',
                            searchInput: true,
                            filterItemTemplate: (item) => <div>{item.label}</div>,
                        },
                        body: (session) => (
                            <div className={'flex align-items-center gap-2'}>
                                {session.archived && <Tag value={'ARCHIVE'} severity={'danger'} />}{' '}
                                {session.formation.title}
                            </div>
                        ),
                    },
                    {
                        header: 'Temps passé',
                        size: 100,
                        body: (item) =>
                            loadingTimeReport ? (
                                <i className="pi pi-spin pi-spinner" />
                            ) : (
                                <div>
                                    {item.participant.elapsed_time
                                        ? Duration.fromMillis(item.participant.elapsed_time).toFormat('hh:mm:ss')
                                        : '-'}
                                </div>
                            ),
                    },
                    {
                        header: 'Avancement',
                        size: 120,
                        body: (item) => (
                            <div>
                                {item.participant.progress === -1
                                    ? ' - '
                                    : new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 2 }).format(
                                          item.participant.progress || 0
                                      )}{' '}
                                %
                            </div>
                        ),
                    },
                    {
                        header: 'Date de début',
                        size: 120,
                        filter: {
                            type: 'sort',
                            field: 'start_date',
                        },
                        body: (session) => <div>{DateTime.fromISO(session.start_date).toFormat('dd/MM/yyyy')}</div>,
                    },
                    {
                        header: 'Statut',
                        size: 100,
                        body: (item) =>
                            !item.participant.blocked ? (
                                <Badge value="ACTIF" severity="success" />
                            ) : (
                                <Badge value="BLOQUÉ" severity="warning" />
                            ),
                    },
                    {
                        header: 'Date de fin',
                        size: 120,
                        filter: {
                            type: 'sort',
                            field: 'end_date',
                        },
                        body: (session) => <div>{DateTime.fromISO(session.end_date).toFormat('dd/MM/yyyy')}</div>,
                    },
                    {
                        frozen: true,
                        size: 100,
                        alignFrozen: 'right',
                        body: ParticipantDropdown,
                    },
                ]}
                values={completeSessions as Array<ExtendedSession>}
                header={{
                    title: 'Liste des sessions',
                    actions: [
                        <span className="p-input-icon-left mr-3">
                            <i className="pi pi-search" />
                            <InputText
                                placeholder="Rechercher une session"
                                value={filter}
                                onChange={(e) => setFilter(e.target.value)}
                            />
                        </span>,
                        <Button
                            className="he-button--primary--md mr-3"
                            style={{ height: 32 }}
                            onClick={(e) => loadTimeReport()}
                        >
                            Récupérer les temps passés <i className="pi pi-refresh" />
                        </Button>,
                        <Button
                            className="he-button--primary--md"
                            style={{ height: 32 }}
                            onClick={(e) => groupedRef.current?.toggle(e)}
                        >
                            Action groupées <i className="pi pi-angle-down" />
                        </Button>,
                    ],
                }}
            />
        </div>
    );
};
