import { ActivitySchema } from '@Schemas/activity.schema';
import { Module } from '@Types/Module';
import { Regexes } from '@Utils/regex.utils';
import { array, mixed, number, object, SchemaOf, string } from 'yup';

export const ModuleSchema: SchemaOf<Omit<Module, 'created_at' | 'updated_at'>> = object().shape({
    module_id: string().required().matches(Regexes.v4),
    title: string().required(),
    cover_image_url: string().nullable().notRequired().default(null),
    description: string().nullable().notRequired(),
    activities: array().of(ActivitySchema),
    type: mixed().oneOf(['quizz', 'validating_quizz', 'audit']).required(),
    estimated_duration: number().min(0).required(),
    estimated_duration_unit: mixed().oneOf(['s', 'j', 'h', 'm']).required(),
    minimal_duration: number().notRequired().nullable(),
    threshold: mixed().when('type', {
        is: (data: string) => data === 'validating_quizz' || data === 'audit',
        then: number().min(0).required(),
        otherwise: number().min(0).nullable().defined().default(null),
    }),
    quizz_type: mixed().when('type', {
        is: (data: string) => data === 'validating_quizz' || data === 'quizz',
        then: mixed().oneOf(['default', 'initial', 'final']).required(),
        otherwise: mixed().notRequired().nullable(),
    }),
    minimal_duration_unit: mixed().when('minimal_duration', {
        is: (duration?: number) => duration == undefined,
        then: (schema) => schema.notRequired().nullable(),
        otherwise: (schema) => schema.oneOf(['s', 'j', 'h', 'm']).required(),
    }),
    audit_type: mixed().when('type', {
        is: 'audit',
        then: mixed().oneOf(['initial', 'final']).required(),
        otherwise: mixed().notRequired().nullable(),
    }),
    min_records: number().when('type', {
        is: 'audit',
        then: number().min(0).required(),
        otherwise: number().notRequired().nullable(),
    }),
    max_records: number().when('type', {
        is: 'audit',
        then: number().min(0).required(),
        otherwise: number().notRequired().nullable(),
    }),
    categories: array()
        .of(
            object().shape({
                category_id: string().required(),
                label: string().required(),
                color: string().required(),
                success_text: string().required(),
                failure_text: string().required(),
                activity_ids: array().of(string().required()).required(),
            })
        )
        .when('type', {
            is: 'audit',
            then: array().required(),
            otherwise: array().notRequired().nullable(),
        }),
});
