import React, { useMemo } from 'react';
import styled from 'styled-components';
import { classNames } from 'primereact/utils';
import type { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { InputTextarea } from 'primereact/inputtextarea';

export type CustomInputProps = {
    field?: string;
    formik?: ReturnType<typeof useFormik<any>>;
    label?: string;
    icon?: string;
    placeholder?: string;
    iconPos?: 'left' | 'right';
    type?: string;
    value?: string;
    onChange?: (change: string) => void;
    showInlineError?: boolean;
    customError?: string;
    disabled?: boolean;
    className?: string | (boolean | string | undefined)[];
    onPressEnter?: () => void;
    onClickIcon?: () => void;
    maxLength?: number;
    height?: number;
    style?: React.CSSProperties;
};

const CustomInputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
`;
export const CustomInput: React.FC<CustomInputProps> = (props) => {
    const defaultProps = useMemo<CustomInputProps>(
        () => ({
            type: 'text',
            iconPos: 'left',
            className: [],
            showInlineError: true,
            ...props,
        }),
        [props]
    );

    const onChangeWithFormik = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const formik = defaultProps.formik;
        const field = defaultProps.field;
        if (formik && field && defaultProps.maxLength !== undefined) {
            formik.setFieldValue(field, event.target.value.substring(0, defaultProps.maxLength), false);
        } else if (formik && field && defaultProps.maxLength === undefined) {
            formik.setFieldValue(field, event.target.value, false);
        }
    };

    const colorGradientMap: [number, string][] = [
        [300, '#EA2828'],
        [250, '#EAA828'],
        [200, '#f3c30b'],
        [150, '#A3CC2E'],
        [0, '#2ECC71'],
    ];

    return (
        <CustomInputWrapper
            style={defaultProps.style}
            className={classNames(
                ...(typeof defaultProps.className === 'string'
                    ? [defaultProps.className]
                    : defaultProps.className ?? [])
            )}
        >
            {defaultProps.label && (
                <label className="he-paragraph--regular gray-500 mb-2" htmlFor={defaultProps.field}>
                    {defaultProps.label}
                    {defaultProps.maxLength && defaultProps.formik && defaultProps.field && (
                        <span
                            className={classNames(
                                'text-xs ml-2',
                                defaultProps.formik.values[defaultProps.field].length >= defaultProps.maxLength &&
                                    'font-bold'
                            )}
                            style={{
                                color:
                                    colorGradientMap.find(
                                        ([n]) => defaultProps.formik!.values[defaultProps.field!].length >= n
                                    )?.[1] ?? colorGradientMap[0][1],
                            }}
                        >
                            {defaultProps.formik.values[defaultProps.field].length}/{defaultProps.maxLength}
                        </span>
                    )}
                </label>
            )}
            {defaultProps.type === 'text' &&
                (defaultProps.icon ? (
                    <div
                        className={classNames(
                            `p-input-icon-${defaultProps.iconPos} w-full`,
                            ((defaultProps.formik &&
                                defaultProps.field &&
                                defaultProps.formik.errors[defaultProps.field]) ||
                                defaultProps.customError) &&
                                'p-inputtext-invalid'
                        )}
                    >
                        <i
                            className={classNames(defaultProps.icon, defaultProps.onClickIcon && 'cursor-pointer')}
                            onClick={defaultProps.onClickIcon}
                        />
                        <InputText
                            id={defaultProps.field}
                            name={defaultProps.field}
                            disabled={defaultProps.disabled}
                            placeholder={defaultProps.placeholder}
                            value={
                                defaultProps.formik && defaultProps.field
                                    ? defaultProps.formik.values[defaultProps.field]
                                    : defaultProps.value
                            }
                            className="w-full"
                            style={{ height: defaultProps.height }}
                            onChange={(event) => props.onChange?.(event.target.value) || onChangeWithFormik(event)}
                        />
                    </div>
                ) : (
                    <div
                        className={classNames(
                            'w-full',
                            ((defaultProps.formik &&
                                defaultProps.field &&
                                defaultProps.formik.errors[defaultProps.field]) ||
                                defaultProps.customError) &&
                                'p-inputtext-invalid'
                        )}
                    >
                        <InputText
                            id={defaultProps.field}
                            disabled={defaultProps.disabled}
                            name={defaultProps.field}
                            placeholder={defaultProps.placeholder}
                            style={{ height: defaultProps.height }}
                            value={
                                defaultProps.formik && defaultProps.field
                                    ? defaultProps.formik.values[defaultProps.field]
                                    : props.value
                            }
                            className="w-full"
                            onChange={(event) => props.onChange?.(event.target.value) || onChangeWithFormik(event)}
                        />
                    </div>
                ))}
            {defaultProps.type === 'textarea' &&
                (defaultProps.icon ? (
                    <div
                        className={classNames(
                            `p-input-icon-${defaultProps.iconPos} w-full`,
                            ((defaultProps.formik &&
                                defaultProps.field &&
                                defaultProps.formik.errors[defaultProps.field]) ||
                                defaultProps.customError) &&
                                'p-inputtext-invalid'
                        )}
                    >
                        <i className={defaultProps.icon} onClick={defaultProps.onClickIcon} />
                        <InputTextarea
                            id={defaultProps.field}
                            name={defaultProps.field}
                            disabled={defaultProps.disabled}
                            style={{ minHeight: defaultProps.height }}
                            placeholder={defaultProps.placeholder}
                            value={
                                defaultProps.formik && defaultProps.field
                                    ? defaultProps.formik.values[defaultProps.field]
                                    : defaultProps.value
                            }
                            className="w-full"
                            onChange={(event) => props.onChange?.(event.target.value) || onChangeWithFormik(event)}
                        />
                    </div>
                ) : (
                    <div
                        className={classNames(
                            'w-full',
                            ((defaultProps.formik &&
                                defaultProps.field &&
                                defaultProps.formik.errors[defaultProps.field]) ||
                                defaultProps.customError) &&
                                'p-inputtext-invalid'
                        )}
                    >
                        <InputTextarea
                            id={defaultProps.field}
                            disabled={defaultProps.disabled}
                            name={defaultProps.field}
                            placeholder={defaultProps.placeholder}
                            style={{ minHeight: defaultProps.height }}
                            value={
                                defaultProps.formik && defaultProps.field
                                    ? defaultProps.formik.values[defaultProps.field]
                                    : props.value
                            }
                            className="w-full"
                            onChange={(event) => props.onChange?.(event.target.value) || onChangeWithFormik(event)}
                        />
                    </div>
                ))}
            {defaultProps.type === 'password' && (
                <div className="w-full">
                    <Password
                        id={defaultProps.field}
                        disabled={defaultProps.disabled}
                        name={defaultProps.field}
                        placeholder={defaultProps.placeholder}
                        value={
                            defaultProps.formik && defaultProps.field
                                ? defaultProps.formik.values[defaultProps.field]
                                : props.value
                        }
                        toggleMask
                        feedback={false}
                        className={classNames(
                            'w-full',
                            defaultProps.formik &&
                                defaultProps.field &&
                                defaultProps.formik.errors[defaultProps.field] &&
                                'p-inputtext-invalid'
                        )}
                        onChange={(event) => props.onChange?.(event.target.value) || onChangeWithFormik(event)}
                    />
                </div>
            )}
            {defaultProps.showInlineError &&
                defaultProps.formik &&
                defaultProps.field &&
                typeof defaultProps.formik.errors[defaultProps.field] === 'string' && (
                    <p className="he-paragraph--small color-red mt-1">
                        {defaultProps.formik && (defaultProps.formik.errors[defaultProps.field] as string)}
                    </p>
                )}
            {defaultProps.customError && (
                <p className="he-paragraph--small color-red mt-1">{defaultProps.customError}</p>
            )}
        </CustomInputWrapper>
    );
};
